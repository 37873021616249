






import { Component, Vue } from 'vue-property-decorator';
import WatchLiveEditPane from '@/components/WatchLive/WatchLiveEditPane.vue';

@Component({
  components: {
    WatchLiveEditPane,
  },
})
export default class WatchLive extends Vue {
}
